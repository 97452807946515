import { FatalErrorBoundary } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { useIsBrowser } from '@redwoodjs/prerender/browserUtils'

import { RecoilRoot } from 'recoil'
import ReactGA from 'react-ga'
import FatalErrorPage from './pages/FatalErrorPage'
import axios from 'axios'

import { GeistProvider } from '@geist-ui/react'

import { createGlobalStyle } from 'styled-components'
const GlobalStyles = createGlobalStyle`
  :root {
  --brand-orange: #ffb900;
  --brand-purple: #390d47;
  --border-color: #EBEEF5;
  }
  body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`

const myTheme = {
  layout: {
    gap: '14pt',
    pageWidth: '1152pt',
    pageWidthWithMargin: '1200pt',
    radius: '3px',
  },
  palette: {
    accents_1: '#ebe7ed',
    accents_2: '#d7cfda',
    accents_3: '#b09eb5',
    accents_4: '#74567e',
    accents_5: '#390d47',
    accents_6: '#280932',
    accents_7: '#1d0724',
    accents_8: '#110415',
    background: '#ffffff',
    foreground: '#060107',
    secondary: '#886e91',
  },
}

import Routes from 'src/Routes'

import './index.css'
import './styles/icons.css'
import './styles/text.css'
import './tailwind.css'

ReactGA.initialize(process.env.GA_ID)

export let scToken = ''

export const baseURL = process.env.DEV
  ? 'http://localhost:3333/api/'
  : 'https://api.stemcut.com/api/'

// Set our default headers
export let HTTP = null

//console.log(process.env)
const App = () => {
  const browser = useIsBrowser()

  if (browser) {
    scToken = window.localStorage.getItem('scToken')

    HTTP = axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${scToken || ''}`,
      },
    })
  }

  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RecoilRoot>
        <RedwoodApolloProvider>
          <GeistProvider theme={myTheme}>
            <GlobalStyles />
            <Routes token={scToken} />
          </GeistProvider>
        </RedwoodApolloProvider>
      </RecoilRoot>
    </FatalErrorBoundary>
  )
}

export default App
