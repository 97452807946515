// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage
// TODO: add 'Private' back to the list of things to import and protect routes
import { useEffect } from 'react'
import { Router, Route } from '@redwoodjs/router'
import { useSetRecoilState } from 'recoil'
import { currentUser } from './atoms'

const Routes = ({ token }) => {
  const setUserToken = useSetRecoilState(currentUser)

  useEffect(() => {
    setUserToken(token)
  }, [token, setUserToken])

  return (
    <Router>
      <Route path="/journal" page={JournalPage} name="journal" />
      <Route path="/journal/list" page={ListJournalsPage} name="listJournals" />
      <Route path="/my-strains" page={MyStrainsPage} name="myStrains" />
      <Route path="/profile" page={ProfilePage} name="profile" />
      <Route path="/collaborations" page={CollaborationsPage} name="collaborations" />
      <Route path="/favorites" page={FavoritesPage} name="favorites" />
      <Route path="/breed" page={BreedPage} name="breed" />
      <Route path="/lab" page={LabPage} name="lab" />
      <Route path="/pub/journal" page={PublicJournalPage} name="publicJournal" />
      <Route prerender path="/privacy" page={PrivacyPage} name="privacy" />
      <Route prerender path="/terms" page={TermsPage} name="terms" />
      <Route prerender path="/strain/{slug}" page={StrainPage} name="strain" />
      <Route prerender path="/strain/{slug}/lovers" page={LoversPage} name="lovers" />
      <Route prerender path="/strain/{slug}/journals" page={StrainJournalsPage} name="strainJournals" />
      <Route prerender path="/strain/{slug}/watchers" page={WatchersPage} name="watchers" />
      <Route prerender path="/pub/journals" page={PublicJournalsPage} name="publicJournals" />
      <Route prerender path="/contact" page={ContactPage} name="contact" />
      <Route prerender path="/pricing" page={PricingPage} name="pricing" />
      <Route prerender path="/logout" page={LogoutPage} name="logout" />
      <Route prerender path="/guide" page={GuidePage} name="guide" />
      <Route prerender path="/login" page={LoginPage} name="login" />
      <Route prerender path="/forgot-password" page={RecoverPasswordPage} name="recoverPassword" />
      <Route prerender path="/register" page={RegisterPage} name="register" />
      <Route prerender path="/" page={HomePage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
