import { atom } from 'recoil'

export const currentUser = atom({
  key: 'currentUser',
  default: null,
})

export const userMe = atom({
  key: 'userMe',
  default: null,
})
