// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful. =)

export default () => (
  <main>
    <style
      dangerouslySetInnerHTML={{
        __html: `
              html, body {
                margin: 0;
              }
              html * {
                box-sizing: border-box;
              }
              main {
                display: flex;
                align-items: center;
                text-align: center;
                background-color: #E2E8F0;
                height: 100vh;
              }
              section {
                background-color: white;
                border-radius: 0.25rem;
                width: 32rem;
                padding: 1rem;
                margin: 0 auto;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
              }
              h1 {
                font-size: 2rem;
                margin: 0;
                font-weight: 500;
                line-height: 1;
                color: #2D3748;
              }
              .page404 {
                background-color: #000;
                padding-top: 100px;
                height: 100vh;
                font-family: 'Press Start 2P', monospace;
                font-size: 36px;
                font-weight: 700;
                color: #fff;
                width: 100%;
              }

              .cursor-pointer {
                cursor: pointer;
              }

              .reaper {
                width: 97%;
                position: relative;
                top: 4px;
                left: 2px;
              }

              .box-shadow {
                /*  -webkit-box-shadow: 0px 0px 3px 24px rgba(232,207,156,0.77);
              -moz-box-shadow: 0px 0px 3px 24px rgba(232,207,156,0.77);
              box-shadow: 0px 0px 3px 24px rgba(232,207,156,0.77); */
                background: url('../assets/404-pattern.png') repeat rgba(232, 207, 156, 0.77);
                padding: 12px;
              }

              .message-error {
                background-color: #fadaaf;
                color: #000;
                text-transform: uppercase;
                word-spacing: 6px;
                width: 100%;
                text-align: center;
                margin: auto;
                height: 200px;
                underline overline dotted
              }

              .message-error p {
                text-align: left;
                line-height: 66px;
                height: 62px;
                padding: 6px;
                text-decoration: underline dotted;
              }
            `,
      }}
    />

    <div className="flex flex-row justify-center items-center page404">
      <div className="flex flex-col items-center">
        <div className="w-1/2">
          <div className="box-shadow">
            <img src="../assets/404.png" className="reaper" alt="" />
          </div>
        </div>
        <div className="w-full">
          <div className="message-error">
            <p>
              It&apos;s a sad thing that
              <br />
              your adventures have
              <br />
              ended here!!
              <br />
              &nbsp;
            </p>
          </div>
        </div>
        <div className="w-full">
          <a href="/" className="column is-12 cursor-pointer">
            Click Here to go home...
          </a>
        </div>
      </div>
    </div>
  </main>
)
